import React, { Component } from "react";
import CreateNewItem from "../Components/createNewItem/CreateNewItem";
import AdminNavbar from "../Components/AdminNavbar/AdminNavbar";

export default class CreateItem extends Component {
  render() {
    return (
      <>
        <AdminNavbar />
        <CreateNewItem />
      </>
    );
  }
}

import "./AdminRegister.scss";
import { redirect, useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import { database } from "../js/firebase";
import { set, ref, push, child, update } from "firebase/database";
import React, { Component } from "react";
import { useAuth } from "../../Contexts/AuthContext";

const AdminRegister = () => {
  let emailRef = useRef();
  let passwordRef = useRef();
  let confirmPasswordRef = useRef();
  const { signup, currentUser } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  async function handleSubmit(e) {
    e.preventDefault();
    if (passwordRef.current.value !== confirmPasswordRef.current.value) {
      return setError("The passwords you just entered do not match!");
    }

    try {
      setLoading(true);
      await signup(emailRef.current.value, passwordRef.current.value);
      alert("Successfully Logged in! Click ok to procceed to the Dashboard");
      navigate("/Adminlog");
    } catch (error) {
      if (error.code == "auth/email-already-in-use") {
        setError("The email address is already in use");
      } else if (error.code == "auth/invalid-email") {
        setError("The email address is not valid.");
      } else if (error.code == "auth/operation-not-allowed") {
        setError("Operation not allowed.");
      } else if (error.code == "auth/weak-password") {
        setError("The password is too weak.");
      }
    }
    setLoading(false);
  }

  return (
    <div className="AdminRegister">
      <h4 className="LoginMessage">Admin Login to Dashboard</h4>

      <form className="loginComponents" onSubmit={handleSubmit}>
        <label htmlFor="">
          <h5>Email</h5>
          <input
            type="text"
            className="textField email"
            id="email"
            placeholder="email"
            ref={emailRef}
            required
          />
        </label>
        <label htmlFor="">
          <h5>Password</h5>
          <input
            type="password"
            className="textField password"
            id="password"
            placeholder="Password"
            ref={passwordRef}
            required
          />
        </label>
        <label htmlFor="">
          <h5>ConfirmPassword</h5>
          <input
            type="password"
            className="textField password"
            id="confirmPassword"
            placeholder="Confirm Password"
            ref={confirmPasswordRef}
            required
          />
        </label>
        <input
          disabled={loading}
          type="submit"
          className="RegisterBtn"
          value="Register Admin"
        />
        <h6 className="error">{error}</h6>
        <h6>Already have an account? Login here: </h6>
      </form>
    </div>
  );
};

export default AdminRegister;

import "./App.scss";
import Footer from "./Components/Footer/Footer";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import Admin from "./Pages/Admin";
import Dashboard from "./Components/Dashboard/Dashboard";
import React, { Component } from "react";
import AuthProvider from "./Contexts/AuthContext";
import AdminReg from "./Pages/AdminReg";
import CreateItem from "./Pages/CreateItem";
function App() {
  return (
    <>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/*" element={<Home />} />
          <Route path="/adminlog" element={<Admin />} />
          <Route exact path="/adminreg" element={<AdminReg />} />
          <Route path="/adminDashboard" element={<Dashboard />} />
          <Route path="/Createitem" element={<CreateItem />} />
        </Routes>
        <Footer />
      </AuthProvider>
    </>
  );
}

export default App;

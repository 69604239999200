import React, { Component } from "react";
import AdminRegister from "../Components/AdminRegister/AdminRegister";
import AdminNavbar from "../Components/AdminNavbar/AdminNavbar";

class AdminReg extends Component {
  render() {
    return (
      <>
        <AdminNavbar />
        <AdminRegister />
      </>
    );
  }
}

export default AdminReg;

import { render } from "@testing-library/react";
import React, { Component } from "react";
import "./Navbar.scss";
import Image from "../../Media/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
function Navbar() {
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  let menu;

  if (showMenu) {
    console.log(showMenu);
    menu = (
      <div className="mobileMenu">
        <ul>
          <FontAwesomeIcon
            className="burgerMenu"
            icon={faXmark}
            onClick={() => setShowMenu(!showMenu)}
          />
        </ul>
        <a href="">
          <img className="logo" src={Image} alt="" />
        </a>
        <ul>
          <a className="navItemMobile" href="">
            Home
          </a>
        </ul>
        <ul>
          <a className="navItemMobile" href="">
            Our Products
          </a>
        </ul>
        <ul>
          <a className="navItemMobile" href="">
            Happy Customers
          </a>
        </ul>
        <ul>
          <a className="navItemMobile" href="">
            On Sale
          </a>
        </ul>
        <ul>
          <a className="navItemMobile" href="">
            Customize
          </a>
        </ul>
        <ul>
          <a className="navItem" href="/adminlog">
            Admin Login
          </a>
        </ul>
      </div>
    );
  }
  return (
    <div className="Navbar">
      <a>
        <img href="/" className="logo" src={Image} alt="" />
      </a>
      <ul>
        <a className="navItem" href="/">
          Home
        </a>
      </ul>
      <ul>
        <a className="navItem" href="">
          Our Products
        </a>
      </ul>
      <ul>
        <a className="navItem" href="">
          Happy Customers
        </a>
      </ul>
      <ul>
        <a className="navItem" href="">
          On Sale
        </a>
      </ul>
      <ul>
        <a className="navItem" href="">
          Customize
        </a>
      </ul>
      <ul>
        <a className="navItem" href="/adminlog">
          Admin Login
        </a>
      </ul>
      <ul>
        <FontAwesomeIcon
          className="burgerMenu"
          icon={faBars}
          onClick={() => setShowMenu(!showMenu)}
        />
      </ul>
      <a className="contactbtn" href="">
        <p>Contact Us</p>
      </a>

      {menu}
    </div>
  );
}
export default Navbar;

import "./AdminLogin.scss";

import { useRef, useState } from "react";
import { database } from "../js/firebase";
import { set, ref, push, child, update } from "firebase/database";
import React, { Component } from "react";
import { useAuth } from "../../Contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
const AdminLogin = () => {
  let emailRef = useRef();
  let passwordRef = useRef();
  const navigate = useNavigate();
  const { login, currentUser } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const authy = getAuth();
  const user = authy.currentUser;
  const location = useLocation();

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setLoading(true);
      await login(emailRef.current.value, passwordRef.current.value);
      alert("Successfully Logged in! Click ok to procceed to the Dashboard");
      navigate("/AdminDashboard");
    } catch (error) {
      console.log(error.code);
    }
    setLoading(false);
  }
  useEffect(() => {
    if (user && location.pathname.toUpperCase() == "/adminlog".toUpperCase()) {
      navigate("/AdminDashboard");
    }
  });

  return (
    <div className="AdminLogin">
      <h4 className="LoginMessage">Admin Login to Dashboard</h4>

      <form className="loginComponents" onSubmit={handleSubmit}>
        <label htmlFor="">
          <h5>Email</h5>
          <input
            type="text"
            className="textField email"
            id="email"
            placeholder="email"
            ref={emailRef}
            required
          />
        </label>
        <label htmlFor="">
          <h5>Password</h5>
          <input
            type="password"
            className="textField password"
            id="password"
            placeholder="Password"
            ref={passwordRef}
            required
          />
        </label>

        <input
          disabled={loading}
          type="submit"
          className="RegisterBtn"
          value="Admin Login"
        />
        <h6 className="error">{error}</h6>
      </form>
    </div>
  );
};

export default AdminLogin;

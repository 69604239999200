import "./Hero.scss";
import React, { Component } from "react";
import { useEffect } from "react";
import HoodieB from "../../Media/hoodieb.png";
import HoodieW from "../../Media/hoodiew.png";
import Abstract from "../../Media/abstract.png";
import TextHero from "../../Media/textHero.png";
import HeroMobile from "../../Media/heroMobile.png";
import Aos from "aos";
import "aos/dist/aos.css";
const Hero = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="Hero">
      <div data-aos="fade-right">
        <div data-aos="fade-right">
          <img className="abstract" src={Abstract} alt="" />
        </div>
        <img className="hoodieb" src={HoodieB} alt="" />
      </div>

      <div data-aos="fade-right">
        <img className="hoodiew" src={HoodieW} alt="" />
      </div>

      <div className="text" data-aos="fade-left">
        <img className="textImage" src={TextHero} alt="" />
      </div>
      <div className="heroMobile">
        <img className="heroMobileImage" src={HeroMobile} alt="" />
      </div>
    </div>
  );
};
export default Hero;

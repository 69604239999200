import "./HotAndNew.scss";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Component } from "react";
import { useAuth } from "../../Contexts/AuthContext";
import { getDatabase, onValue, ref, set } from "firebase/database";

function HotAndNew() {
  const [product, setProduct] = useState("");
  const [products, setProducts] = useState([]);
  const { fetchProducts } = useAuth();
  const db = getDatabase();
  useEffect(() => {
    const productsRef = ref(db, "products/");
    try {
      onValue(productsRef, (snapshot) => {
        setProducts([]); //resetting the array
        const data = snapshot.val();
        if (data !== null) {
          Object.values(data).map((product) => {
            setProducts((oldArray) => [...oldArray, product]);
          });
        }
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  return (
    <div className="HandN">
      <div className="Title">
        <h1>Hot & New</h1>
      </div>

      <Splide
        options={{
          rewind: true,
          gap: "5rem",
          perPage: 3,
          breakpoints: {
            640: {
              perPage: 1,
            },
          },
        }}
        aria-label="My Favorite Images"
      >
        {products.map((product) => (
          <SplideSlide>
            <div className="MainCard">
              <div className="Card">
                <img src={product.prodImage} alt="" />
              </div>
              <h3 className="title">{product.prodName}</h3>
              <h5 className="color">{product.prodColor}</h5>
              <h5 className="price">{product.prodPrice} $</h5>
            </div>
          </SplideSlide>
        ))}
      </Splide>
    </div>
  );
}
export default HotAndNew;

import React, { Component } from "react";
import Hero from "../Components/Hero/Hero";
import HotAndNew from "../Components/HotAndNew/HotAndNew";
import Navbar from "../Components/Navbar/Navbar";

class Home extends Component {
  render() {
    return (
      <div className="Home">
        <Navbar />
        <Hero />
        <HotAndNew />
      </div>
    );
  }
}

export default Home;

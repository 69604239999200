import React, { Component } from "react";
import PostsPanel from "./DashboardComponents/PostsPanel/PostsPanel";
import UsersPanel from "./DashboardComponents/UsersPanel/UsersPanel";
import AdminNavbar from "../AdminNavbar/AdminNavbar";

export default class extends Component {
  render() {
    return (
      <div className="Dashboard">
        <AdminNavbar />
        <PostsPanel />
        <UsersPanel />
      </div>
    );
  }
}

import React, { useContext, useEffect, useState } from "react";
import { auth } from "../Components/js/firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { getDatabase, onValue, ref, set } from "firebase/database";
import { child, get } from "firebase/database";
import {
  getStorage,
  uploadBytesResumable,
  uploadBytes,
} from "firebase/storage";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export default function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password).then;
  }
    function getCurrentDate(separator = "") {
      let newDate = new Date();
      let date = newDate.getDate();
      let month = newDate.getMonth() + 1;
      let year = newDate.getFullYear();

      return `${year}${separator}${
        month < 10 ? `0${month}` : `${month}`
      }${separator}${date}`;
    }
    function CreateNewProduct(
      prodName,
      prodPrice,
      prodColor,
      prodGenre,
      prodSubGenre,
      prodQuantity,
      prodImage,
      prodCategory
    ) {
      const prodDate = getCurrentDate();
      const prodId =
        "" + getCurrentDate() + prodName + prodGenre + prodColor + prodCategory;
      const db = getDatabase();
      return set(ref(db, "/products/" + prodId), {
        prodId: prodId,
        prodName: prodName,
        prodPrice: prodPrice,
        prodColor: prodColor,
        prodGenre: prodGenre,
        prodSubGenre: prodSubGenre,
        prodQuantity: prodQuantity,
        prodImage: prodImage,
        prodCategory: prodCategory,
        prodDate: prodDate,
      });
    }

  function fetchProducts(type) {
    const db = getDatabase();
    const productsRef = ref(db, type + "/");
    onValue(productsRef, (snapshot) => {
      const data = snapshot.val();
      if (data !== null) {
        return Object.values(data);
      }
    });
  }

  function signup(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }
  async function checkProduct(prodId) {
    let checkProdResult = false;
    const dbRef = ref(getDatabase());
    await get(child(dbRef, `products/${prodId}`))
      .then((snapshot) => {
        if (snapshot.val() == null) {
          checkProdResult = false;
        } else {
          checkProdResult = true;
        }
      })
      .catch((error) => {
        console.error(error);
      });

    return checkProdResult;
  }
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });
    return unsubscribe;
  }, []);
  const userSignOut = () => {
    signOut(auth)
      .then(() => {
        console.log("SignOut Successfull");
      })
      .catch((error) => console.log(error));
  };

  const value = {
    currentUser,
    signup,
    login,
    userSignOut,
    CreateNewProduct,
    fetchProducts,
    checkProduct,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

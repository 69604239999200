import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import "./UsersPanel.scss";
import {
  faAdd,
  faDashboard,
  faFileEdit,
  faFireFlameCurved,
  faFireFlameSimple,
  faPenAlt,
  faPencilRuler,
  faPenNib,
  faPenSquare,
  faPersonCircleCheck,
  faPersonCirclePlus,
  faPlusCircle,
  faUserPen,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
export default class UsersPanel extends Component {
  render() {
    return (
      <div className="Panel UsersPanel">
        <div className="title">
          <h1>Users Panel</h1>

          <div className="iconsContainer">
            <div className="section">
              <a href="/adminreg">
                <FontAwesomeIcon
                  className="sectionIcon"
                  icon={faUserPlus}
                ></FontAwesomeIcon>
              </a>
              <h4 className="sectionName">Add New Admin</h4>
            </div>
            <div className="section">
              <FontAwesomeIcon
                className="sectionIcon"
                icon={faUserPen}
              ></FontAwesomeIcon>
              <h4 className="sectionName">Modify Admin</h4>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faFireFlameCurved,
  faFolderPlus,
  faPencilRuler,
} from "@fortawesome/free-solid-svg-icons";
import "./PostsPanel.scss";
import { faFantasyFlightGames } from "@fortawesome/free-brands-svg-icons";
import { useNavigate } from "react-router-dom";

function PostsPanel() {
  const navigate = useNavigate();
  return (
    <div className="Panel PostsPanel">
      <div className="title">
        <h1>Posts Panel</h1>

        <div className="iconsContainer">
          <div className="section">
            <a href="/createitem">
              <FontAwesomeIcon
                className="sectionIcon"
                icon={faFolderPlus}
              ></FontAwesomeIcon>
            </a>
            <h4 className="sectionName">Create New Post</h4>
          </div>
          <div className="section">
            <FontAwesomeIcon
              className="sectionIcon"
              icon={faEdit}
            ></FontAwesomeIcon>
            <h4 className="sectionName">Modify Post</h4>
          </div>
          <div className="section">
            <FontAwesomeIcon
              className="sectionIcon"
              icon={faFireFlameCurved}
            ></FontAwesomeIcon>
            <h4 className="sectionName">Create Hot Item</h4>
          </div>
          <div className="section">
            <FontAwesomeIcon
              className="sectionIcon"
              icon={faPencilRuler}
            ></FontAwesomeIcon>
            <h4 className="sectionName">Modify Hot Item</h4>
          </div>
        </div>
      </div>
    </div>
  );
}
export default PostsPanel;


import { render } from "@testing-library/react";
import React, { Component } from "react";
import "./AdminNavbar.scss";
import Image from "../../Media/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import { useAuth } from "../../Contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { auth } from "../js/firebase";
import { signOut } from "firebase/auth";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useLocation } from "react-router-dom";
let uName = "";
const authy = getAuth();
onAuthStateChanged(authy, (user) => {
  if (user) {
    uName = user.email;
  }
});

function AdminNavbar() {
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const { authUser } = useAuth();
  const location = useLocation();
  const user = authy.currentUser;

  function LogOut() {
    onAuthStateChanged(authy, (user) => {
      if (user) {
        signOut(auth).then(() => {
          console.log("Logged Out Successfully");
          navigate("/Adminlog");
        });
      }
    });
  }
  let menu;
  useEffect(() => {
    if (
      !user &&
      location.pathname.toUpperCase() == "/AdminDashboard".toUpperCase()
    ) {
      navigate("/adminlog");
    }
    if (!user && location.pathname.toUpperCase() == "/Adminreg".toUpperCase()) {
      navigate("/adminlog");
    }
    if (
      !user &&
      location.pathname.toUpperCase() == "/Createitem".toUpperCase()
    ) {
      navigate("/adminlog");
    }
  });
  if (showMenu) {
    console.log(showMenu);
    menu = (
      <div className="mobileMenu">
        <ul>
          <FontAwesomeIcon
            className="burgerMenu"
            icon={faXmark}
            onClick={() => setShowMenu(!showMenu)}
          />
        </ul>
        <a href="">
          <img className="logo" src={Image} alt="" />
        </a>
        <ul>
          <a className="navItem" href="">
            Home
          </a>
        </ul>
        <ul>
          <a className="navItemMobile" href="">
            Our Products
          </a>
        </ul>
        <ul>
          <a className="navItemMobile" href="">
            Happy Customers
          </a>
        </ul>
        <ul>
          <a className="navItemMobile" href="">
            On Sale
          </a>
        </ul>
        <ul>
          <a className="navItemMobile" href="">
            Customize
          </a>
        </ul>
      </div>
    );
  }
  return (
    <div className="AdminNavbar">
      <a href="/admindashboard">
        <img className="logo" src={Image} alt="" />
      </a>

      <ul>{uName}</ul>
      <ul>
        <a className="navItem" href="">
          Our Products
        </a>
      </ul>
      <ul>
        <a className="navItem" href="">
          Happy Customers
        </a>
      </ul>
      <ul>
        <a className="navItem" href="">
          On Sale
        </a>
      </ul>
      <ul>
        <a className="navItem" href="">
          Customize
        </a>
      </ul>
      <ul>
        <FontAwesomeIcon
          className="burgerMenu"
          icon={faBars}
          onClick={() => setShowMenu(!showMenu)}
        />
      </ul>
      <a className="contactbtn" onClick={() => LogOut()}>
        <p>LogOut</p>
      </a>
      {menu}
    </div>
  );
}
export default AdminNavbar;

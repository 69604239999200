import React, { Component, useState, useRef, useEffect } from "react";
import "./CreateNewItem.scss";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Contexts/AuthContext";
import { getDatabase, onValue, ref, set } from "firebase/database";

const CreateNewItem = () => {
  let nameRef = useRef();
  let priceRef = useRef();
  let colorRef = useRef();
  let genreRef = useRef();
  let subgenreRef = useRef();
  let quantityRef = useRef();
  let categoriesRef = useRef();
  let imageRef = useRef();
  const { CreateNewProduct, checkProduct, fetchFromDB } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [category, setCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [genre, setGenre] = useState("");
  const [genres, setGenres] = useState([]);
  const [subgenre, setSubGenre] = useState("");
  const [subgenres, setSubGenres] = useState([]);
  const db = getDatabase();

  useEffect(() => {
    const categoriesRef = ref(db, "categories/");
    const genreRef = ref(db, "genre/");
    const subgenreRef = ref(db, "subgenre/");

    try {
      onValue(categoriesRef, (snapshot) => {
        setCategories([]); //resetting the array
        const data = snapshot.val();
        if (data !== null) {
          Object.values(data).map((category) => {
            setCategories((oldArray) => [...oldArray, category]);
          });
        }
      });
      onValue(genreRef, (snapshot) => {
        setGenres([]);
        const data = snapshot.val();
        if (data !== null) {
          Object.values(data).map((genre) => {
            setGenres((oldArray) => [...oldArray, genre]);
          });
        }
      });
      onValue(subgenreRef, (snapshot) => {
        setSubGenres([]);
        const data = snapshot.val();
        if (data !== null) {
          Object.values(data).map((subgenre) => {
            setSubGenres((oldArray) => [...oldArray, subgenre]);
          });
        }
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  async function handleSubmit(e) {
    setError("");
    e.preventDefault();
    try {
      await CreateNewProduct(
        nameRef.current.value,
        priceRef.current.value,
        colorRef.current.value,
        genreRef.current.value,
        subgenreRef.current.value,
        quantityRef.current.value,
        imageRef.current.value,
        categoriesRef.current.value
      );

      setError("Successfully Added the new Product!");
      nameRef.current.value = "";
      priceRef.current.value = "";
      colorRef.current.value = "";
      genreRef.current.value = "";
      subgenreRef.current.value = "";
      quantityRef.current.value = "";
      imageRef.current.value = "";
    } catch (error) {
      setError(error.code);
      console.error(error);
    }
  }
  return (
    <div className="CreateNewItem">
      <form onSubmit={handleSubmit}>
        <label htmlFor="">
          <h5>Product Name</h5>
          <input
            type="text"
            className="textField productName"
            id="prodName"
            placeholder="Product Name"
            ref={nameRef}
            required
          />
        </label>
        <label htmlFor="">
          <h5>Category</h5>
          <select
            required
            name="categories"
            ref={categoriesRef}
            id="categories"
            onChange={(e) => {
              categoriesRef.current.value = e.target.value;
              console.log(subgenres);
            }}
          >
            <option value={"Please Select"}> Please Select</option>
            {categories.map((category) => (
              <option value={category.name}>{category.name}</option>
            ))}
          </select>
        </label>
        <label htmlFor="">
          <h5>Product Price</h5>
          <input
            type="number"
            className="textField productPrice"
            id="prodPrice"
            placeholder="1000000$"
            ref={priceRef}
            required
          />
        </label>
        <label htmlFor="">
          <h5>Product Color</h5>
          <input
            type="text"
            className="textField productColor"
            id="productColor"
            placeholder="productColor"
            ref={colorRef}
            required
          />
        </label>
        <label htmlFor="">
          <h5>Genre</h5>
          <select
            required
            name="genre"
            id="genre"
            ref={genreRef}
            onChange={(e) => {
              genreRef.current.value = e.target.value;
            }}
          >
            <option value={"Please Select"}>Please Select</option>
            {genres.map((genre) => (
              <option value={genre.name}>{genre.name}</option>
            ))}
          </select>
        </label>
        <label htmlFor="">
          <h5>Sub-Genre</h5>
          <select
            required
            name="sub-genre"
            id="sub-genre"
            ref={subgenreRef}
            onChange={(e) => {
              subgenreRef.current.value = e.target.value;
            }}
          >
            <option value={"Please Select"}>Please Select</option>
            {subgenres.map((subgenre) => (
              <option value={subgenre.name}>{subgenre.name}</option>
            ))}
          </select>
        </label>
        <label htmlFor="">
          <h5>In Stock Quantity</h5>
          <input
            type="number"
            className="textField productName"
            id="prodNumber"
            placeholder="30"
            ref={quantityRef}
            required
          />
        </label>
        <label htmlFor="">
          <h5>Product Image Link</h5>
          <input
            type="text"
            className="textField prodImage"
            id="prodImage"
            placeholder="example.imgur.com/example"
            ref={imageRef}
            required
          />
        </label>
        <input type="submit" className="btn AddItemBtn" value="Add item" />
        <h4 className="errorMessage">{error}</h4>
      </form>
    </div>
  );
};
export default CreateNewItem;

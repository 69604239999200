import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import React, { Component } from "react";
import Logo from "../../Media/logo.png";
import "./Footer.scss";
import {
  faEnvelope,
  faMailBulk,
  faMailReply,
} from "@fortawesome/free-solid-svg-icons";
export class Footer extends Component {
  render() {
    return (
      <div className="Footer">
        <div className="Box left-Box">
          <a href="#Home">
            <img className="logo" src={Logo} alt="" />
          </a>
          <p className="copyrights">©2023 Urban Storm Tech</p>
        </div>

        <div className="Box middle-Box">
          <h2>Follow Us On:</h2>
          <div className="socialIcons">
            <a href="">
              <FontAwesomeIcon className="socialIcon" icon={faTwitter} />
            </a>
            <a href="">
              <FontAwesomeIcon className="socialIcon" icon={faInstagram} />
            </a>
            <a href="">
              <FontAwesomeIcon className="socialIcon" icon={faFacebook} />
            </a>
          </div>
          <h2>Or Contact Us:</h2>
          <div className="icons">
            <a className="iconPlaceholder" href="">
              <FontAwesomeIcon className="socialIcon" icon={faEnvelope} />
              Urbanstorm@gmail.com
            </a>
            <a className="iconPlaceholder" href="">
              <FontAwesomeIcon className="socialIcon" icon={faWhatsapp} />
              +961 76-099556
            </a>
          </div>
        </div>
        <div className="Box right-Box">
          <ul>
            <a className="navItem" href="">
              Home
            </a>
          </ul>
          <ul>
            <a className="navItem" href="">
              On Sale
            </a>
          </ul>
          <ul>
            <a className="navItem" href="">
              Customize
            </a>
          </ul>
          <ul>
            <a className="navItem" href="">
              Our Products
            </a>
          </ul>
          <ul>
            <a className="navItem" href="">
              Happy Customers
            </a>
          </ul>
        </div>
      </div>
    );
  }
}

export default Footer;

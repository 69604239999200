import React, { Component } from "react";
import AdminLogin from "../Components/AdminLogin/AdminLogin";
import AdminNavbar from "../Components/AdminNavbar/AdminNavbar";

class Admin extends Component {
  render() {
    return (
      <div>
        <AdminLogin></AdminLogin>
      </div>
    );
  }
}

export default Admin;
